import { DataAccess } from "../dataaccess/data.access";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { S25Util } from "../util/s25-util";
import { Proto } from "../pojo/Proto";
import Unwrap = Proto.Unwrap;
import { EventData } from "./event.service";
import { DeepPartial } from "../pojo/Util";

export class GridsService {
    @Cache({ targetName: "GridsService" })
    public static getList() {
        const url = "/grids/list";
        const injected = DataAccess.injectCaller(url, "GridsService.getGrids");
        return S25Util.Maybe(DataAccess.get<GridsListResponse>(injected));
    }

    @Invalidate({ serviceName: "GridsService", methodName: "getList" })
    public static delete(id: number) {
        const url = `/grids/grid?id=${id}`;
        const injected = DataAccess.injectCaller(url, "GridsService.delete");
        return S25Util.Maybe(DataAccess.delete(injected));
    }

    @Invalidate({ serviceName: "GridsService", methodName: "getList" })
    public static create(data: DeepPartial<GridListItem>) {
        const url = `/grids/grid`;
        const injected = DataAccess.injectCaller(url, "GridsService.delete");
        return S25Util.Maybe(DataAccess.post<GridListItem>(injected, data));
    }

    @Invalidate({ serviceName: "GridsService", methodName: "getList" })
    public static update(data: DeepPartial<GridListItem> & Pick<GridListItem, "id">) {
        const url = `/grids/grid`;
        const injected = DataAccess.injectCaller(url, "GridsService.update");
        return S25Util.Maybe(DataAccess.patch<GridListItem>(injected, data));
    }

    public static getData(id: number) {
        const url = `/grids/grid/data.json?id=${id}`;
        const injected = DataAccess.injectCaller(url, "GridService.getData");
        return S25Util.Result(DataAccess.get<GridDataResponse>(injected));
    }

    @Invalidate({ serviceName: "GridsService", methodName: "getList" })
    public static setShared(id: number, users: number[]) {
        const url = `/grids/grid/share?id=${id}`;
        const injected = DataAccess.injectCaller(url, "GridService.setShared");
        return S25Util.Result(DataAccess.put<never>(injected, users));
    }

    // TODO: Make sticky?
    public static async getPreferences() {
        return {
            startHour: 7,
            endHour: 17,
            dows: ["MWF", "TR"],
        };
    }
}

export type GridListItem = Unwrap<Awaited<ReturnType<typeof GridsService.getList>>>[number];

type GridsListResponse = {
    id: number;
    name: string;
    eventQuery: { queryId: number; queryName: string };
    type: string;
    owner: number;
    isOwner: boolean;
    sharedWith: { grid: number; contId: number; name: string }[];
}[];

export type GridDataResponse = {
    error?: string;
    events?: EventData[];
};
